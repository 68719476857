import { useState } from 'react';
import './App.css';
import { translations, defaultLanguage } from './translations';
import Modal from './components/Modal';
import { galleryData } from './data/galleryData';

function App() {
  const [currentLang] = useState(defaultLanguage);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images] = useState(galleryData);

  return (
    <div className="gallery-container">
      <h1 className="gallery-title">{translations[currentLang].siteTitle}</h1>
      <div className="image-grid">
        {images.map((image, index) => (
          <img 
            key={index}
            src={image.thumb} 
            alt={`Gallery item ${index}`}
            className="gallery-image"
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>
      {selectedImage && (
        <Modal 
          image={selectedImage.full}
          title={selectedImage.title?.[currentLang] || 'Untitled'}
          description={selectedImage.description?.[currentLang] || 'No description available'}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </div>
  );
}

export default App;