export const galleryData = [
  {
    thumb: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/audio-monitoring.jpg',
    full: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/audio-monitoring.jpg',
    title: {
      en: "Audio Monitoring",
      ja: "音声モニタリング"
    },
    description: {
      en: "Recording the sounds of the afterlife",
      ja: "来世の音を記録する"
    }
  },
  {
    thumb: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/hands-xray-bottle.jpg',
    full: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/hands-xray-bottle.jpg',
    title: {
      en: "Hands X-ray Bottle",
      ja: "手のX線瓶"
    },
    description: {
      en: "A detailed view of the hands holding an X-ray bottle",
      ja: "手のX線瓶を持った詳細なビュー"
    }
  },
  {
    thumb: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/skull-xray.jpg',
    full: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/skull-xray.jpg',
    title: {
      en: "Skull X-ray",
      ja: "頭蓋のX線"
    },
    description: {
      en: "A detailed view of the skull",
      ja: "頭蓋の詳細なビュー"
    }
  },
  {
    thumb: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/results.jpeg',
    full: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/results.jpeg',
    title: {
      en: "Results",
      ja: "結果"
    },
    description: {
      en: "The results of the experiment",
      ja: "実験の結果"
    }
  },
  {
    thumb: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/fun-after-death.jpg',
    full: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/fun-after-death.jpg',
    title: {
      en: "Fun After Death",
      ja: "死後の楽しみ"
    },
    description: {
      en: "The fun things people do after death",
      ja: "死後に人々が行う楽しみのこと"
    }
  },
  {
    thumb: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/water-quality.jpg',
    full: 'https://randomblogs.blob.core.windows.net/publicblogimages/atheistmythology/water-quality.jpg',
    title: {
      en: "Water Quality",
      ja: "水質"
    },
    description: {
      en: "The quality of water",
      ja: "水の質"
    }
  },
]; 