import React from 'react';

function Modal({ image, title, description, onClose }) {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        <h2 className="modal-title">{title}</h2>
        <img src={image} alt={title} className="modal-image" />
        <p className="modal-description">{description}</p>
      </div>
    </div>
  );
}

export default Modal; 